import React, { useEffect, useState } from 'react';
import CartItemList from './CartItemList';
import { getCheckoutUrl } from '../lib/FetchProducts';

const CartPane = ({cartObj, placeDetails}) => {
  let totalQuantity = 0;
  let subtotal = 0;

  const [cartToggle, setCartToggle] = useState(false);
  const [cart, setCart] = useState(cartObj);
  const [checkoutUrl, setCheckoutUrl] = useState(null);

  const overlayClass = cartToggle ? 'cart-overlay visible' : 'cart-overlay';


  // Listen for reserveBarCartUpdated event on the document.
  document.addEventListener('reserveBarCartUpdated', (e) => {
    console.log("reserveBarCartUpdated", e.detail);
    setCartToggle(true);

    // Load cart from local storage.
    const cartStg = JSON.parse(localStorage.getItem('reserveBarCart'));
    setCart(cartStg);
  });

  document.addEventListener('cartDisplay', (e) => {
    // Update the cart based on local storage.
    const cartStg = JSON.parse(localStorage.getItem('reserveBarCart'));
    setCart(cartStg);

    setCartToggle(!cartToggle);
  });

  if(cart) {
    totalQuantity = cart.itemCount;
    subtotal = cart.subtotal;
  }

  useEffect(() => {
    if (cart) {
      totalQuantity = cart.itemCount || 0;
      subtotal = cart.subtotal || 0;
    }

    // Clear the checkout URL when the cart changes
    setCheckoutUrl(null);
    
    const lsPlaceDetails = JSON.parse(localStorage.getItem('reserveBarPlaceDetails'));

    // Ensure cart and lsPlaceDetails are not null or undefined before calling getCheckoutUrl
    if (cart && lsPlaceDetails) {
      getCheckoutUrl(cart.id, lsPlaceDetails).then((checkout) => {
        setCheckoutUrl(checkout);
      });
    }
  }, [cart, placeDetails]); // Dependency array, getCheckoutUrl will only re-run if these values change


  const redirectToCheckout = () => {
    console.log("Redirect to checkout");
    console.log(checkoutUrl);
    if (checkoutUrl) {
      let link = document.createElement('a');
      link.href = checkoutUrl.url;
      link.style.display = 'none';
      document.body.appendChild(link);
  
      // Dispatch a click event on the link
      link.click();
  
      // Check after a short delay if the URL has changed
      // setTimeout(() => {
      //   if (window.location.href === checkoutUrl.url) {
      //     console.log("Navigation seems to be successful.");
      //   } else {
      //     console.log("Navigation may have been blocked or failed.");
      //     // Fallback to direct assignment or other navigation means
      //     window.location.href = checkoutUrl.url;
      //   }
      // }, 100); // Delay in milliseconds
  
      document.body.removeChild(link);
    }
  }
  

  return (
    <>
    <div className={overlayClass} onClick={() => setCartToggle(false)}></div>    
    <div className={`cart-pane ${cartToggle ? 'open' : ''}`}>
    <p className="cart-tab" onClick={() => setCartToggle(!cartToggle)}><svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.83 38.08">
                        <defs>
                        </defs>
                        <g id="Layer_1-2" data-name="Layer 1">
                            <g>
                            <path className="cartSvg" d="m9.56,10.01h-2.44c-3.32,0-6.01,2.69-6.01,6.01v14.94c0,3.32,2.69,6.01,6.01,6.01h21.58c3.32,0,6.01-2.69,6.01-6.01v-14.94c0-3.32-2.69-6.01-6.01-6.01h-1.86"></path>
                            <path className="cartSvg" d="m26.85,9.77c0-4.78-3.87-8.65-8.65-8.65s-8.65,3.87-8.65,8.65c0,.08,0,.16.01.24h17.28c0-.08.01-.16.01-.24Z"></path>
                            </g>
                        </g>
                        </svg></p>
      <div className="cart-header">
        <h2>Cart</h2>
        <div className="closePane" onClick={() => setCartToggle(false)}>✕</div>
      </div>
      <div className="cart-content">
        <CartItemList cart={cart} setCart={setCart} />
      </div>
      {cart?.itemCount > 0 && 
       <div className="cart-footer">
        <div className="cart-summary">
          <div>Subtotal({totalQuantity} Item{totalQuantity > 1 && "s"})</div>
          <div>${subtotal}</div>
        </div>
        <div className="cart-checkout-wrapper">
          {checkoutUrl &&
            <a className="btn cart-checkout"
              href={checkoutUrl.url}>
              Continue to Checkout
            </a>
          }
          {!checkoutUrl &&
            <a className="btn cart-checkout" disabled>
              Preparing Cart...
            </a>
          }
        </div>
      </div>
       }  
    </div>
    </>

  );
};

export default CartPane;
