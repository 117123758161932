import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import CartPane from './components/CartPane';

import { getCart } from './lib/FetchProducts';


// Create a new element directly under the body element.
var cartDiv = document.createElement("div");
cartDiv.id = "cart";
document.body.appendChild(cartDiv);

// Add an LI element to the UL with ID of menu-top-new-nav
var li = document.createElement("li");
li.innerHTML = '<a href="#" id="cart-display"><svg data-name="cart-icon" id="cart-icon" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><title/><path fill="#ffffff" d="M12.11,44H35.89A4,4,0,0,0,40,39.6l-2.26-22A4.07,4.07,0,0,0,33.63,14H32V12a8,8,0,0,0-16,0v2H14.37a4.07,4.07,0,0,0-4.09,3.6L8,39.6A4,4,0,0,0,12.11,44ZM18,12a6,6,0,0,1,12,0v2H18Zm-5.73,5.81A2.07,2.07,0,0,1,14.37,16H16v2.89a1.5,1.5,0,1,0,2,0V16H30v2.89a1.5,1.5,0,1,0,2,0V16h1.63a2.07,2.07,0,0,1,2.1,1.81l2.26,22a1.89,1.89,0,0,1-.5,1.49,2.16,2.16,0,0,1-1.6.7H12.11a2.16,2.16,0,0,1-1.6-.7A1.93,1.93,0,0,1,10,40H34a1,1,0,0,0,0-2H10.2Z"/></svg></a>';
var li2 = document.createElement("li");
li2.innerHTML = '<a href="#" id="cart-display"><svg data-name="cart-icon" id="cart-icon" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><title/><path fill="#ffffff" d="M12.11,44H35.89A4,4,0,0,0,40,39.6l-2.26-22A4.07,4.07,0,0,0,33.63,14H32V12a8,8,0,0,0-16,0v2H14.37a4.07,4.07,0,0,0-4.09,3.6L8,39.6A4,4,0,0,0,12.11,44ZM18,12a6,6,0,0,1,12,0v2H18Zm-5.73,5.81A2.07,2.07,0,0,1,14.37,16H16v2.89a1.5,1.5,0,1,0,2,0V16H30v2.89a1.5,1.5,0,1,0,2,0V16h1.63a2.07,2.07,0,0,1,2.1,1.81l2.26,22a1.89,1.89,0,0,1-.5,1.49,2.16,2.16,0,0,1-1.6.7H12.11a2.16,2.16,0,0,1-1.6-.7A1.93,1.93,0,0,1,10,40H34a1,1,0,0,0,0-2H10.2Z"/></svg></a>';


document.getElementById("menu-top-new-nav").appendChild(li);
document.getElementById("menu-top-menu-mobile").appendChild(li2);

// When this li is clicked fire the cartDisplay event.
li.addEventListener('click', (e) => {
  e.preventDefault();
  const event = new CustomEvent('cartDisplay');
  document.dispatchEvent(event);
});

// When this li is clicked fire the cartDisplay event.
li2.addEventListener('click', (e) => {
  e.preventDefault();
  console.log("Ouch")
  const event = new CustomEvent('cartDisplay');
  document.dispatchEvent(event);
});

// Async function to get to get the cart.
(async () => {

  const reserveBarCart = await getCart();
  const placeDetails = JSON.parse(localStorage.getItem('reserveBarPlaceDetails'));

  // Only render the purchaseUI if there is a group ID on the page.
  if(window.reserveBarGroupId) {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  }


  // Create a second app on the page.
  const cart = ReactDOM.createRoot(cartDiv);
  cart.render(
    <React.StrictMode>
      {/* <CartPane /> */}
      <CartPane cartObj={reserveBarCart} placeDetails={placeDetails}></CartPane>
    
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
})();