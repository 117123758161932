import React, { useState } from 'react';
import { addToCart, removeFromCart } from '../lib/FetchProducts';
import CartItem from './CartItem';

const CartItemList = ({cart, setCart}) => {

  if(!cart || cart?.itemCount === 0 ) {
    return (
      <>
        <div className="cart-item">
            You spirit cart is currently empty.
        </div>
        <div className="cart-item">
          <a href="/shop-whiskey">Click here to shop Michter's whiskey.</a>
        </div>
      </>
    )
  }

  const removeItem = (item) => {
    removeFromCart(item.product.id, cart.id).then((updatedCart) => {
        // Set localStorage cart.
        localStorage.setItem('reserveBarCart', JSON.stringify(updatedCart));
        setCart(updatedCart);
    })
  }

  const updateItem = (item, qty) => {
    console.log(item);
    console.log("Update item to qty: ", qty);
    addToCart(item.product.id, qty, cart.id).then((updatedCart) => {
        setCart(updatedCart);
    })
  }
  
  return (
    <>    
      {/* Loop over cart.cartItems and display the items in the cart */}
        {cart.cartItems.map((item) => {
            return (
                <CartItem key={item.id} item={item} removeItem={removeItem} updateItem={updateItem} />
            )
        })}
    </>
  );
};

export default CartItemList;
